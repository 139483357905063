import React from 'react'

export default function MetaEN() {
  return (
    <>
      <header>
        <h1>Privacy policy statement</h1>
        <p>Combined record of processing activities and information document</p>
        <p>Valid from 29/03/2023.</p>
        <p>Last updated 20/10/2023.</p>
      </header>
      <section>
        <h3>1 Data controller</h3>
        <p>Name: Taitopilvi Oy (&ldquo;Taitopilvi&rdquo;)</p>
        <p>Business ID: 2786133-7</p>
        <p>Address: Hannikaisenkatu 20 (floor 2), 40100 Jyv&auml;skyl&auml;, Finland</p>
        <h3>2 Data protection contact information</h3>
        <p>Enquiries and requests regarding data protection may be sent to info@taitopilvi.fi.</p>
        <h3>3 Our data protection practices in general</h3>
        <p>Taitopilvi processes personal data according to the applicable legislation, including the General Data Protection Regulation (GDPR, Regulation (EU) 2016/679, including amendments), and this privacy policy statement.</p>
        <p>We take into account the requirements of data protection legislation in all our business operations and require the same from our subcontractors and other partners.</p>
        <p>We familiarise and train our staff on data protection requirements and guidelines to ensure compliance.</p>
        <h3>4 Basis and purpose of the register</h3>
        <p>Taitopilvi maintains a marketing and customer register for the following purposes: managing customer relationships and other relationships based on relevant associations; targeting and analysing our offering; producing, providing, and developing our services; and market research and other statistical purposes.</p>
        <p>Taitopilvi may use the registered data to produce marketing for customers and potential customers (including direct electronic marketing) and target such marketing as legally permitted.</p>
        <p>The customer company&rsquo;s contact persons listed in the agreement have the right to define the contact persons registered in Taitopilvi&rsquo;s marketing and customer register for the customer account in question.</p>
        <h3>5 Registered data</h3>
        <p>The register may contain the following data about the contact persons of the customer company:</p>
        <ul className="bullet">
          <li>First and last name</li>
          <li>Role and/or position in the company</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Marketing permissions and prohibitions</li>
          <li>Customer relationship information (e.g. billing and payment information, product and order information, customer feedback, and correspondence)</li>
        </ul>
        <p>In addition to the above, the register may contain information related to the contact person of the customer company or potential customer company, such as:</p>
        <ul className="bullet">
          <li>Information about upcoming events (dates and locations of meetings)</li>
          <li>History of past events (correspondence, offers, and meetings)</li>
          <li>Information on the use of Taitopilvi marketing content.</li>
        </ul>
        <h3>6 Regular data sources</h3>
        <p>Taitopilvi collects personal data during customer relationships and during the work done to establish a potential customer relationship.</p>
        <p>Data subjects&rsquo; data is regularly collected from the individuals in question through forms on the website (HubSpot).</p>
        <h3>7 Personal data retention</h3>
        <p>The data controller only keeps personal data for as long as necessary for the purpose for which the data is processed.</p>
        <h3>8 Data subject rights</h3>
        <p>Data subjects possess all rights granted to them by the GDPR, including review and management.</p>
        <h4>8.1 Right of access</h4>
        <p>Data subjects have the right to review their information in Taitopilvi&rsquo;s marketing and customer register. Data review may be requested from the data controller by email. The request must clearly specify the data that can be used to identify the individual.</p>
        <h4>8.2 Right to rectification, right to restriction of processing</h4>
        <p>Data subjects have the right to the correction or deletion of erroneous, incomplete, unnecessary, or outdated data by making a request to Taitopilvi. In addition, data subjects can request the restriction of the processing of their personal data in certain cases or otherwise object to processing.</p>
        <h4>8.3 Right to erasure, right to object</h4>
        <p>Data subjects have the right to have their data erased or object to its processing when the processing is based on a legitimate interest. Data subjects always have the right to object to direct marketing by using the link found at the end of Taitopilvi emails or by contacting Taitopilvi.</p>
        <h4>8.4 Right to lodge a complaint with a supervisory authority</h4>
        <p>Data subjects can lodge a complaint regarding the processing of their personal data with the supervisor authority: the Data Protection Ombudsman.</p>
        <h3>9 Protection of personal data</h3>
        <p>Taitopilvi organises its information security and protection of personal data according to the industry&rsquo;s best practices. Personal data is protected against unauthorised access and processing as well as unlawful and accidental destruction, loss, and damage. In addition, Taitopilvi develops its data protection practices continuously.</p>
        <p>We process all personal data as confidential, and all our users have committed to the confidentiality of the processed data and to follow our company&rsquo;s information and data security instructions. Access to confidential data is controlled by a central or system-specific user directory. Only designated persons may grant access to and permissions in the system once the person requesting access has been identified and the need to access confidential information can be verified. System access and permissions are reviewed regularly.</p>
        <p>If we outsource the processing of personal data to third parties, we enter into the agreements required by data protection legislation with these third parties. We do this to ensure compliance with this privacy policy statement and the applicable regulations and official instructions with regard to personal data processing.</p>
        <h3>10 Disclosure and transfer of data</h3>
        <p>Taitopilvi only discloses personal data for justified needs and only in a lawful manner.</p>
        <h3>11 Cookies</h3>
        <p>We only set cookies, that are necessary for the proper functioning of the site.</p>
      </section>
    </>
  )
}