import React from 'react'

export default function MetaFI() {
  return (
    <>
      <header>
        <h1>Tietosuojaseloste</h1>
        <p>Yhdistetty rekisteriseloste ja informointiasiakirja</p>
        <p>Astunut voimaan 29.3.2023.</p>
        <p>Viimeksi päivitetty 18.10.2023.</p>
      </header>
      <section>
        <h3>1. Rekisterinpit&auml;j&auml;</h3>
        <p>Nimi: Taitopilvi Oy, &quot;Taitopilvi&quot;</p>
        <p>Y-tunnus: 2786133-7</p>
        <p>Osoite: Hannikaisenkatu 20 (2. krs), 40100 Jyv&auml;skyl&auml;</p>
        <h3>2. Yhteystiedot tietosuoja-asioissa</h3>
        <p>Tietosuojaa koskevat kysymykset ja pyynn&ouml;t voit l&auml;hett&auml;&auml; osoitteeseen info@taitopilvi.fi.</p>
        <h3>3. Yleinen kuvaus tietosuojak&auml;yt&auml;nn&ouml;st&auml;mme</h3>
        <p>Taitopilvi k&auml;sittelee henkil&ouml;tietoja sovellettavan lains&auml;&auml;d&auml;nn&ouml;n, mukaan lukien Euroopan unionin yleisen tietosuoja-asetuksen (2016/679 mahdollisine muutoksineen, GDPR), sek&auml; t&auml;m&auml;n tietosuojaselosteen mukaisesti.</p>
        <p>Huomioimme tietosuojalains&auml;&auml;d&auml;nn&ouml;n asettamat vaatimukset kaikessa liiketoiminnassamme ja edellyt&auml;mme samaa alihankkijoiltamme ja muilta yhteisty&ouml;kumppaneiltamme.</p>
        <p>Perehdyt&auml;mme ja koulutamme henkil&ouml;st&ouml;&auml;mme tietosuojaa koskevista vaatimuksista ja ohjeistuksista, jotta ty&ouml;ntekij&auml;mme osaavat toimia niiden mukaisesti.</p>
        <h3>4. Rekisterin pit&auml;misen peruste ja k&auml;ytt&ouml;tarkoitus</h3>
        <p>Taitopilvi yll&auml;pit&auml;&auml; markkinointi- ja asiakasrekisteri&auml; asiakassuhteen tai muun asialliseen yhteyteen perustuvan suhteen hallintaan, hoitamiseen, yll&auml;pit&auml;miseen ja kehitt&auml;miseen, tarjonnan kohdistamiseen ja analysointiin, palveluiden tuottamiseen, tarjoamiseen ja kehitt&auml;miseen sek&auml; markkinatutkimuksiin ja muihin tilastollisiin tarkoituksiin.</p>
        <p>Rekisteritietoja voidaan k&auml;ytt&auml;&auml; Taitopilven ja asiakkaan tai potentiaalisen asiakkaan v&auml;liseen markkinointiin (mukaan lukien s&auml;hk&ouml;iseen suoramarkkinointiin) ja sen kohdentamiseen lains&auml;&auml;d&auml;nn&ouml;n sallimissa rajoissa.</p>
        <p>Asiakasyrityksen sopimuksellisilla yhteyshenkil&ouml;ill&auml; on oikeus m&auml;&auml;ritell&auml; Taitopilven markkinointi- ja asiakasrekisteriin tallennettavat yhteyshenkil&ouml;t kyseisen asiakkuuden osalta.</p>
        <h3>5. Rekisterin sis&auml;lt&auml;m&auml;t tiedot</h3>
        <p>Rekisteri voi sis&auml;lt&auml;&auml; asiakasyrityksen yhteyshenkil&ouml;ist&auml; seuraavia tietoja:</p>
        <ul className="bullet">
          <li>Etu- ja sukunimi</li>
          <li>Teht&auml;v&auml; ja/tai asema yrityksess&auml;</li>
          <li>S&auml;hk&ouml;posti</li>
          <li>Puhelinnumero</li>
          <li>Markkinointiluvat ja -kiellot</li>
          <li>Asiakassuhdetta koskevat tiedot (esimerkiksi laskutus- ja maksutiedot, tuote- ja tilaustiedot, asiakaspalautteet ja yhteydenotot)</li>
        </ul>
        <p>Edell&auml; mainittujen lis&auml;ksi rekisteri voi sis&auml;lt&auml;&auml; asiakasyrityksen tai potentiaalisen asiakasyrityksen yhteyshenkil&ouml;&ouml;n liittyvi&auml; tietoja, kuten</p>
        <ul className="bullet">
          <li>Tietoa tulevista tapahtumista (palaverien ajankohdat sek&auml; paikat)</li>
          <li>Historiatietoa menneist&auml; tapahtumista (yhteydenotto-, tarjous-, palaveri- ja kokoustietoa)</li>
          <li>Tietoa Taitopilven markkinointisis&auml;lt&ouml;jen k&auml;yt&ouml;st&auml;.</li>
        </ul>
        <h3>6. S&auml;&auml;nn&ouml;nmukaiset tietol&auml;hteet</h3>
        <p>Taitopilvi ker&auml;&auml; henkil&ouml;tietoja asiakassuhteen aikana sek&auml; mahdollisen asiakassuhteen syntymiseksi teht&auml;v&auml;n ty&ouml;n aikana.</p>
        <p>Rekister&ouml;ityj&auml; koskevia tietoja ker&auml;t&auml;&auml;n s&auml;&auml;nn&ouml;llisesti henkil&ouml;ilt&auml; itselt&auml;&auml;n sivuston lomakkeiden kautta (HubSpot).</p>
        <h3>7. Henkil&ouml;tietojen s&auml;ilytysaika</h3>
        <p>Rekisterinpit&auml;j&auml; ei s&auml;ilyt&auml; henkil&ouml;tietoja kauemmin kuin on tarpeen ottaen huomioon henkil&ouml;tietojen k&auml;sittelyn tarkoituksen.</p>
        <h3>8. Rekister&ouml;idyn oikeudet</h3>
        <p>Rekister&ouml;idyll&auml; on kaikki asetuksen mukaiset oikeudet omiin tietoihinsa, niiden tarkistamiseen ja hallitsemiseen.</p>
        <h4>8.1. Oikeus tarkastaa tiedot</h4>
        <p>Rekister&ouml;idyll&auml; on oikeus tarkastaa, mit&auml; h&auml;nt&auml; koskevia tietoja Taitopilven markkinointi- ja asiakasrekisteriin on tallennettu. Tietojen tarkastuspyynn&ouml;n voi l&auml;hett&auml;&auml; s&auml;hk&ouml;postilla rekisterinpit&auml;j&auml;lle. Pyynn&ouml;ss&auml; on oltava selke&auml;sti yksil&ouml;ityn&auml; ne tiedot, joilla rekister&ouml;ity voidaan yksil&ouml;id&auml;.</p>
        <h4>8.2. Oikeus tiedon korjaamiseen ja k&auml;sittelyn rajaamiseen</h4>
        <p>Rekister&ouml;idyll&auml; on oikeus vaatia virheellisten, puutteellisten, tarpeettomien tai vanhentuneiden tietojensa korjaamista tai poistamista ilmoittamalla asiasta Taitopilvelle. Lis&auml;ksi rekister&ouml;idyll&auml; on my&ouml;s tietyiss&auml; tilanteissa oikeus pyyt&auml;&auml; henkil&ouml;tietojensa k&auml;sittelyn rajoittamista tai muuten vastustaa k&auml;sittely&auml;.</p>
        <h4>8.3. Oikeus vaatia tietojen poistamista tai vastustaa tietojen k&auml;sittely&auml;</h4>
        <p>Rekister&ouml;idyll&auml; on oikeus vaatia henkil&ouml;tietojensa poistamista tai vastustaa niiden k&auml;sittely&auml; silloin, kun se perustuu oikeutettuun etuun. Rekister&ouml;idyll&auml; on aina oikeus vastustaa suoramarkkinointia Taitopilven l&auml;hett&auml;m&auml;n s&auml;hk&ouml;postin lopusta l&ouml;ytyv&auml;n linkin kautta tai ottamalla yhteytt&auml; Taitopilveen.</p>
        <h4>8.4. Oikeus tehd&auml; valitus valvontaviranomaiselle</h4>
        <p>Rekister&ouml;ity voi tehd&auml; henkil&ouml;tietojen k&auml;sittelyst&auml; valituksen valvovalle viranomaiselle, joka on tietosuojavaltuutettu.</p>
        <h3>9. Henkil&ouml;tietojen suojaus</h3>
        <p>Taitopilvell&auml; tietoturvallisuus ja henkil&ouml;tietojen suojaaminen on organisoitu alan parhaiden k&auml;yt&auml;nt&ouml;jen mukaisesti siten, ett&auml; henkil&ouml;tiedot on suojattu luvattomalta p&auml;&auml;sylt&auml; ja k&auml;sittelylt&auml; sek&auml; laittomasti ja vahingossa tapahtuvalta tuhoamiselta, menett&auml;miselt&auml; ja turmeltumiselta. Lis&auml;ksi k&auml;yt&auml;nt&ouml;j&auml; tietojen suojaamiseksi kehitet&auml;&auml;n jatkuvasti.</p>
        <p>K&auml;sittelemme henkil&ouml;tietoja luottamuksellisina, ja kaikki k&auml;ytt&auml;j&auml;mme ovat sitoutuneet k&auml;sitelt&auml;vien tietojen salassapitoon sek&auml; noudattamaan yrityksemme tietosuoja- ja tietoturvallisuusohjeistuksia. P&auml;&auml;synhallinta luottamuksellisiin tietoihin on toteutettu keskitetyn tai j&auml;rjestelm&auml;kohtaisen k&auml;ytt&auml;j&auml;hakemiston kautta. Vain nimetyt henkil&ouml;t voivat my&ouml;nt&auml;&auml; p&auml;&auml;syn ja k&auml;ytt&ouml;oikeudet j&auml;rjestelm&auml;&auml;n, jos p&auml;&auml;sy&auml; pyyt&auml;nyt henkil&ouml; on tunnistettu ja tarve p&auml;&auml;st&auml; luottamuksellisiin tietoihin pystyt&auml;&auml;n varmistamaan. J&auml;rjestelm&auml;&auml;n p&auml;&auml;sy ja k&auml;ytt&ouml;oikeudet tarkistetaan s&auml;&auml;nn&ouml;llisesti.</p>
        <p>Mik&auml;li ulkoistamme henkil&ouml;tietojen k&auml;sittely&auml; kolmansille osapuolille, teemme tietosuojalains&auml;&auml;d&auml;nn&ouml;n edellytt&auml;m&auml;t sopimukset kyseisten kolmansien tahojen kanssa. N&auml;in varmistamme, ett&auml; henkil&ouml;tietojen k&auml;sittely noudattaa t&auml;t&auml; tietosuojaselostetta sek&auml; sovellettavia lakeja, asetuksia ja viranomaism&auml;&auml;r&auml;yksi&auml;.</p>
        <h3>10. Tietojen luovutus tai siirto</h3>
        <p>Taitopilvi luovuttaa henkil&ouml;tietoja vain silloin, kun siihen on perusteltu tarve, ja aina lains&auml;&auml;d&auml;nn&ouml;n vaatimusten mukaisesti.</p>
        <h3>11. Ev&auml;steet</h3>
        <p>Asetamme vain sivuston toiminnan kannalta välttämättömiä evästeitä.</p>
      </section>
    </>
  )
}