import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Layout from '@components/layout'
import MetaEN from '@components/meta-en'
import MetaFI from '@components/meta-fi'
import Box from '@common/box'

function Meta(props) {
  const { i18n } = useTranslation();

  if (i18n.language === 'fi') {
    return (
      <Layout hasTitle={false} hasHeroArea={false}>
        <Box>
          <MetaFI />
        </Box>
      </Layout>
    )
  } else {
    return (
      <Layout hasTitle={false} hasHeroArea={false}>
        <Box>
          <MetaEN />
        </Box>
      </Layout>
    )
  }
}

export default Meta
